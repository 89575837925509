import React from 'react';

import BareBoneStatusPage from './BareBoneStatusPage';

const LoadErrorScreen: React.FC = () => (
  <BareBoneStatusPage>
    <div className="m-4 flex flex-col gap-4 font-sans">
      <h2>Oops, something went wrong...</h2>
      <p>
        We&apos;re sorry for the inconvenience. Please try reloading the page,
        or contact our support if the problem persists.
      </p>
    </div>
  </BareBoneStatusPage>
);

export default LoadErrorScreen;
